@import "style.css";

@import "responsive.css";
@import "font-awesome.css";

#landing {
#id-version1 .ban-btns .buy {
background:#f48756;
}

#id-version1 .ban-btns .buy:hover,#id-version1.callout-content .btnn:hover {
background:rgba(244,135,86,.8);
}

#id-version1.ban-btns .buy {
border-color:#f48756;
}

#service-version1-main .service:hover i,#id-version1 .text-with-btn .btnn:hover,#id-version1 .callout-content .btnn,#id-version1 .sorting-content .btnn:hover,#id-version1 .form form .message3-btn .submit:hover,#id-version1 .formm #senddd:hover {
background:#f48756;
border-color:#f48756;
}

#service-version1-main .service:hover .iconss span:after {
border-bottom-color:#f48756;
}

#id-version1 .text-with-btn ul li:before {
color:#f48756;
}

#id-version1 .service:hover .iconss span:before {
border-top-color:#f48756;
}

#id-version1 .port_wrapper ul li .active:after,.port_wrapper ul li .active:before {
content:"";
background:#f48756!important;
z-index:999;
}

#id-version1 .port_wrapper ul li .active {
color:#f48756!important;
}

#id-version2 #top-social2 .header2 .navbar-inverse .navbar-nav > li > a:hover,#id-version2 #banner-section-style2 .other-content ul li:before,#id-version2 #banner-section-style2 .sub-form2 form .message3-btn .submit:hover,#id-version2 .success-message a:hover {
color:#4183d7!important;
}

#id-version2 #index2-accord .accordion-section .active span:after {
border-bottom-color:#4183d7!important;
}

#id-version2 .callout-message a:hover {
background:#4183d7!important;
color:#fff!important;
border-color:#4183d7!important;
}

#id-version2 .callout-message a {
background:none!important;
border-color:#fff!important;
}

#id-version2 #top-social2 #singh-uppp {
background:#4183d7!important;
color:#fff!important;
}

#id-version3 .header3 .navbar-inverse .navbar-nav > li > a:hover,#id-version3 .other-content ul li:before,#id-version3 .sub-form2 form .message3-btn .submit:hover,#id-version3 .success-message a:hover,#id-version3 .text3-withbtn a {
color:#2ba1ec!important;
}

#id-version3 .accordion-section .active span:after {
border-bottom-color:#2ba1ec!important;
}

#id-version3 .callout-message a:hover,#id-version3 .banner3-content .btnn,#id-version3 .text3-withbtn a:hover,#id-version3 .our-story .btnn:hover {
background:#2ba1ec!important;
border-color:#2ba1ec!important;
}

#id-version3 .text3-withbtn a {
border-color:#2ba1ec!important;
}

#id-version3 .team-img-overlay {
background:rgba(29,183,256,.5)!important;
}

#id-version3 .plan2 .btnn {
color:#2ba1ec!important;
border-color:#2ba1ec!important;
}

#id-version3 .price span {
color:#2ba1ec;
}

#id-version4 .header3 .navbar-inverse .navbar-nav > li > a:hover,#id-version4 .other-content ul li:before,#id-version4 .sub-form2 form .message3-btn .submit:hover,#id-version4 .success-message a:hover,#id-version4 .text3-withbtn a,.tabber-info .btnn,.service4 a:hover,#id-version4 .facts i,#id-version4 .our-brands .btnn:hover,#id-version4 .banner4-content .ver4-pur {
color:#99ac22!important;
}

#id-version4 .text3-line,#id-version4 .open-com1,#id-version4 .clients-line,#id-version4 .subscribe3,#id-version4 .tabber-info .btnn:hover,#id-version4 .plan4-text,#id-version4 .banner4-content .ver4-pur:hover {
background:#99ac22!important;
}

#id-version4 .accordion-section .active span:after {
border-bottom-color:#99ac22!important;
}

#id-version4 .callout-message a:hover,#id-version4 .banner3-content .btnn,#id-version4 .text3-withbtn a:hover,#id-version4 .our-story .btnn:hover,#id-version4 .sub-form4 form .message3-btn .submit {
background:#99ac22!important;
border-color:#99ac22!important;
}

#id-version4 .plan4:hover {
background:rgba(152,171,32,1)!important;
}

#id-version4 .price span {
color:#99ac22;
}

.ban-btns .buy,.port_wrapper ul .active a:after,.port_wrapper ul .active a:before,.sub-form .singh:hover {
background:#6d6d6d;
}

.callout-content .btnn:hover {
background:rgba(208,131,240,.8);
}

.ban-btns .buy,#version5 .navbar-inverse .navbar-nav > li > a:hover,#version5 .buy-now a,.sub-form .singh:hover {
border-color:#fff;
}

.callout-content .btnn,.sorting-content .btnn:hover,.form form .message3-btn .submit:hover,.formm #senddd:hover,.top-form .formm #senddd,.ban-btns .buy,.sub-form .singh {
background:#6d6d6d;
border-color:#6d6d6d;
}

.service:hover .iconss span:after {
border-bottom-color:#6d6d6d;
}

.text-with-btn ul li:before,.port_wrapper ul .active a,.service:hover i,.service i {
color:#6d6d6d;
}

.service:hover .iconss span:before {
border-top-color:#6d6d6d;
}

#version5 .buy-now a {
background:#17a510;
border-color:#17a510;
}

.text-with-btn .btnn {
border-color:#6d6d6d;
}

.text-with-btn .btnn:hover,.sorting-content .btnn:hover,.ban-btns .buy:hover,.sub-form .singh:hover,.top-form .formm #senddd:hover {
background:#5d5d5d;
border-color:#5d5d5d;
}

.port_wrapper ul li .active:after,.port_wrapper ul li .active:before {
content:"";
background:#000!important;
z-index:999;
}

.port_wrapper ul li .active {
color:#000!important;
}

#id-version6 .sub-form2,#id-version6 .title-line1,#id-version6 .accordion-section .active i,#id-version6 .plan:hover,#id-version6 .open-com2,#id-version6 .formm #senddd {
background:#59abe3;
}

#id-version6 .accordion-section .active span:after {
border-bottom-color:#59abe3;
}

#id-version6 .callout-message a:hover {
background:#fff;
color:#59abe3;
border-color:#59abe3;
}

#id-version6 .callout-message a {
background:#5d6b7a;
border-color:#5d6b7a;
}

#id-version6 .com1 i:hover,#id-version6 .com2 i:hover,#id-version6 .com3 i:hover {
background:#59abe3!important;
}

#id-version6 .open-com2:after {
border-color:#59abe3 transparent transparent #59abe3;
}

#id-version6 .formm #senddd:hover {
background:rgba(89,171,227,.9);
}

#singh-uppp {
background:#5d6b7a;
}

#id-version7 .header3 .navbar-inverse .navbar-nav > li > a:hover,#id-version7 .other-content ul li:before,#id-version7 .sub-form2 form .message3-btn .submit:hover,#id-version7 .success-message a:hover,#id-version7 .text3-withbtn a,#id-version7 .form-client .flip-counter.default .digit span {
color:#d2527f!important;
}

#id-version7 .subscribe3 {
background:#3498db!important;
}

#id-version7 #top-form .formm #senddd {
background:#27ae60!important;
border-color:#27ae60!important;
}

#id-version7 .text3-withbtn a {
border-color:#d2527f!important;
}

#id-version7 .team-img-overlay {
background:rgba(210,82,127,.5)!important;
}

#id-version7 .plan2 .btnn {
color:#d2527f!important;
border-color:#d2527f!important;
}

#id-version7 #text-with-btn3-version7 .open-com1:after {
border-color:#d2527f transparent transparent #d2527f !important;
}

#id-version7 #skill-version7 .skill .bar .value {
background-color:#d2527f!important;
}

.price span {
color:#d2527f;
}

.text3-line,.open-com1,.clients-line,.subscribe3,.tabber-info .btnn:hover,.plan4-text,.banner4-content .ver4-pur:hover,.experience-version8 .entry,#thought p:after,#thought p:before,#thought p {
background:#4183d7;
}

.accordion-section .active span:after {
border-bottom-color:#4183d7;
}

.callout-message a:hover,.banner3-content .btnn,.text3-withbtn a:hover,.our-story .btnn:hover {
background:#4183d7;
border-color:#4183d7;
}

#plane-version-8-section .plan4 .price span {
color:#2c2c2c;
}

.sub-form4 form .message3-btn .submit,#sub-form4 form .message3-btn .submit:hover {
background:#c0392b;
border-color:#c0392b;
}

#sub-form4 form .message3-btn .submit:hover {
opacity:.9;
}

.form-v8 #senddd,.form-v8 #sendddd {
background:#4183d7!important;
border-color:#4183d7!important;
}

.plan4:hover {
background:rgba(65,131,215,1);
}

#id-version2 #banner-section-style2 .sub-form2,#id-version2 .title-line1,#id-version2 #index2-accord .accordion-section .active i,#id-version2 .plan:hover,#id-version2 #text-with-version2 .open-com2,#id-version2 #text-with-version2 .com1:hover i,#id-version2 #text-with-version2 .com2:hover i,#id-version2 #text-with-version2 .com3:hover i {
background:#4183d7!important;
}

#id-version2 .plan:hover .price span,#id-version3 .text3-withbtn a:hover,#id-version3 .plan2 .btnn:hover,#id-version4 .banner4-content .ver4-pur:hover,#id-version4 .plan4:hover .price span,#id-version6 #plan-version8 .plan:hover .price span,#singh-uppp:hover,#id-version7 #nav-version7 .navbar-inverse .navbar-nav > li > a,#id-version7 .plan2 .btnn:hover,#id-version7 .text3-withbtn a:hover,#plane-version-8-section .plan4:hover .price span {
color:#fff!important;
}

#id-version2 .price span,.header3 .navbar-inverse .navbar-nav > li > a:hover,.other-content ul li:before,.sub-form2 form .message3-btn .submit:hover,.success-message a:hover,.text3-withbtn a,.tabber-info .btnn,.service4 a:hover,.facts i,.our-brands .btnn:hover,.banner4-content .ver4-pur,#topbar-version8 .header3 .navbar-inverse .navbar-nav > li > a:hover,.service-version8 i {
color:#4183d7;
}

#id-version3 .text3-line,#id-version3 .open-com1,.clients-line,#id-version3 .subscribe3,#id-version3 .com1:hover i,#id-version3 .com2:hover i,#id-version3 .com3:hover i,#id-version3 .plan2 .btnn:hover {
background:#2ba1ec!important;
}

#id-version4 .top-social4 .header3 .navbar-inverse .navbar-nav > li > a:hover,#id-version4 .banner4-content .ver4-pur,#id-version4 .text3-withbtn a,#id-version4 .tabber-info .btnn,#id-version4 .service4 a:hover {
border-color:#99ac22!important;
}

#version5 .navbar-inverse .navbar-nav > li > a,#version5 .buy-now a,#version5 .buy-now a:hover {
color:#fff;
}

#id-version6 .header2 .navbar-inverse .navbar-nav > li > a:hover,#id-version6 .sub-form2 form .message3-btn .submit:hover,#id-version6 .success-message a:hover,#id-version6 #topbar-version6 .header2 .navbar-inverse .navbar-nav > li > a:hover,#id-version6 #plan-version8 .price span {
color:#59abe3;
}

#id-version7 .text3-line,#id-version7 .open-com1,#id-version7 .clients-line,#nav-version7,#id-version7 .com1:hover i,#id-version7 .com2:hover i,#id-version7 .com3:hover i,#id-version7 .plan2 .btnn:hover,#id-version7 #txt-widbtn7 a:hover,#id-version7 #txt-widbtn8 a:hover {
background:#d2527f!important;
}

#id-version7 .accordion-section .active span:after,#id-version7 .form-client.flip-counter.default .digit .line,#id-version7 .form-client .flip-counter.default .digit .line {
border-bottom-color:#d2527f!important;
}

.service4 a:hover,.top-social4 .header3 .navbar-inverse .navbar-nav > li > a:hover,.banner4-content .ver4-pur,.text3-withbtn a,.tabber-info .btnn {
border-color:#4183d7;
}

}